import React, { useState, useEffect } from 'react'
import { isFunction, eq } from 'lodash/fp'
import { isBrowser } from 'helpers'

import {
  Box,
  Flex,
  Text,
  AspectRatio,
  GatsbyImage,
  GatsbyImageContainer,
} from 'system'
import { BoundedBox } from 'src/components/BoundedBox'

// Returns x mod n where x can be positive or negative.
export const mod = (x, n) => ((x % n) + n) % n

const SlideMobile = ({
  children,
  phrase,
  imageFluid,
  isPrevious = false,
  isCurrent = false,
  isNext = false,
  transition = 1000,
  color = 'teal',
  ...props
}) => {
  let zIndex = 1
  if (isPrevious) zIndex = 2
  if (isCurrent) zIndex = 3

  return (
    <Box
      bg="navy"
      opacity={isCurrent || isPrevious ? 1 : 0}
      position="absolute"
      right={0}
      top={0}
      width={1}
      zIndex={zIndex}
      css={`
        transition: opacity ${transition}ms;
      `}
      {...props}
    >
      {imageFluid && (
        <GatsbyImageContainer>
          <AspectRatio x={4} y={3}>
            <GatsbyImage
              fluid={imageFluid}
              alt=""
              css={`
                pointer-events: none;
              `}
            />
          </AspectRatio>
        </GatsbyImageContainer>
      )}
      <BoundedBox
        bg="white"
        color="teal"
        pt={4}
        pb={4}
        pl={2}
        pr={2}
        height="7.5rem"
      />
      <BoundedBox
        bg="white"
        bottom={0}
        color="teal"
        left={0}
        pb={4}
        pl={2}
        position="absolute"
        pr={2}
        pt={3}
        right={0}
        zIndex={1}
      >
        <Text textAlign="center" mb={1} fontSize="small" lineHeight="copy">
          {isFunction(phrase) ? phrase() : phrase}
        </Text>
        <Text
          as="div"
          fontSize="large"
          lineHeight="solid"
          textStyle="caps"
          fontWeight="medium"
          textAlign="center"
        >
          {children}
        </Text>
      </BoundedBox>
    </Box>
  )
}

const SlideDesktop = ({
  children,
  phrase,
  imageFluid,
  isPrevious = false,
  isCurrent = false,
  isNext = false,
  transition = 1000,
  color = 'teal',
  ...props
}) => {
  let zIndex = 1
  if (isPrevious) zIndex = 2
  if (isCurrent) zIndex = 3

  return (
    <AspectRatio
      x={16}
      y={9}
      bg="navy"
      color={color}
      left={0}
      maxHeight="60rem"
      opacity={isCurrent || isPrevious ? 1 : 0}
      position="absolute"
      right={0}
      top={0}
      zIndex={zIndex}
      css={`
        transition: opacity ${transition}ms;
      `}
      {...props}
    >
      {imageFluid && (
        <GatsbyImageContainer
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        >
          <GatsbyImage
            fluid={imageFluid}
            alt=""
            css={`
              pointer-events: none;
            `}
          />
        </GatsbyImageContainer>
      )}
      <BoundedBox position="relative" height="100%">
        {color === 'white' && (
          <Box
            backgroundImage="linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))"
            position="absolute"
            top={0}
            right="-1rem"
            bottom={0}
            width="75%"
          />
        )}
        <Box height="100%" position="relative">
          <Flex
            bottom="46%"
            flexDirection="column"
            alignItems="flex-end"
            position="absolute"
            right={0}
          >
            <Text
              fontSize={['medium', null, 'midLarge']}
              lineHeight="copy"
              maxWidth={['12rem', null, '15rem']}
              mb={[2, null, null, 4]}
            >
              {isFunction(phrase) ? phrase() : phrase}
            </Text>
            <Text
              as="div"
              fontSize={['xxlarge', null, 'xxxlarge', 'xxxxlarge']}
              lineHeight="solid"
              textStyle="caps"
              fontWeight="normal"
            >
              {children}
            </Text>
          </Flex>
        </Box>
      </BoundedBox>
    </AspectRatio>
  )
}

const Slide = ({ isPrevious, isCurrent, isNext, ...props }) => (
  <Box>
    {(isPrevious || isCurrent || isNext) && (
      <>
        <SlideMobile
          display={[null, 'none']}
          isPrevious={isPrevious}
          isCurrent={isCurrent}
          isNext={isNext}
          {...props}
        />
        <SlideDesktop
          display={['none', 'block']}
          isPrevious={isPrevious}
          isCurrent={isCurrent}
          isNext={isNext}
          {...props}
        />
      </>
    )}
  </Box>
)

export const HomepageSlideshow = ({
  children,
  duration = 6000,
  transition = 1000,
  ...props
}) => {
  const [index, setIndex] = useState(0)
  const slidesCount = React.Children.count(children)

  useEffect(() => {
    if (!isBrowser) return

    const interval = window.setInterval(() => {
      setIndex(i => (i + 1) % slidesCount)
    }, duration)

    return () => window.clearInterval(interval)
  }, [])

  return (
    <Box
      as="section"
      bg="navy"
      maxHeight="60rem"
      overflow="hidden"
      position="relative"
      zIndex={1}
      {...props}
    >
      {/* Mobile Spacer */}
      <Box display={[null, 'none']}>
        <AspectRatio x={4} y={3} />
        <Box height="7.5rem" />
      </Box>
      {/* Desktop Spacer */}
      <AspectRatio x={16} y={9} display={['none', 'block']} />
      {React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
          isPrevious: eq(i, mod(index - 1, slidesCount)),
          isCurrent: eq(i, index),
          isNext: eq(i, mod(index + 1, slidesCount)),
          transition,
        })
      )}
    </Box>
  )
}

HomepageSlideshow.Slide = Slide
