import React from 'react'

import { SVG } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { LogoBug } from 'src/components/LogoBug'

export const SpecialIntroText = ({ markdown, children, ...props }) => (
  <BoundedBox
    as="section"
    bg="sand"
    color="navy"
    maxWidth="large"
    overflow="hidden"
    position="relative"
    {...props}
  >
    <SVG
      svg={LogoBug}
      x={1}
      y={1}
      fill="sandLight"
      position="absolute"
      right={0}
      top={0}
      width={['15rem', '20rem']}
      css={`
        transform: translate(10%, 15%);
      `}
    />
    {children || (
      <MarkdownContent
        markdown={markdown}
        map={{
          h1: props => (
            <Heading
              as="h2"
              color="white"
              mb={[2, 4]}
              fontWeight="normal"
              lineHeight="solid"
              {...props}
            />
          ),
        }}
        fontSize={['medium', 'midLarge']}
        lineHeight="copy"
        position="relative"
        textAlign="center"
      />
    )}
  </BoundedBox>
)
