import React from 'react'

import {
  Box,
  Flex,
  Text,
  AspectRatio,
  GatsbyImage,
  GatsbyImageContainer,
  Link,
  SVG,
} from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { IconChevronRight } from 'src/components/IconChevronRight'

const Card = ({ heading, imageFluid, imageAlt, ...props }) => (
  <AspectRatio
    as={Link}
    x={4}
    y={3}
    to="/"
    width={[1, 1 / 2, 1 / 3]}
    {...props}
  >
    <Flex
      bg={imageFluid ? 'black' : 'yellow'}
      flexDirection="column"
      height="100%"
      justifyContent="flex-end"
      p={[3, 4]}
      position="relative"
    >
      {imageFluid && (
        <GatsbyImageContainer
          height="100%"
          left={0}
          position="absolute"
          top={0}
          width="100%"
          opacity={0.4}
          css={`
            transition: opacity ${p => p.theme.transition};

            &:hover {
              opacity: 0.6;
            }
          `}
        >
          <GatsbyImage fluid={imageFluid} alt={imageAlt} />
        </GatsbyImageContainer>
      )}
      <Box
        position="relative"
        css={`
          pointer-events: none;
        `}
      >
        <Heading
          color={imageFluid ? 'white' : 'navy'}
          fontSize="large"
          mb={[1, 2]}
          maxWidth="30rem"
        >
          {heading}
        </Heading>
        <Flex alignItems="center">
          <Text
            color={imageFluid ? 'yellow' : 'navy'}
            fontSize="small"
            fontWeight="bold"
            lineHeight="solid"
            mr={1}
            textStyle="smallCaps"
          >
            More
          </Text>
          <SVG
            svg={IconChevronRight}
            x={11}
            y={18}
            fill={imageFluid ? 'yellow' : 'navy'}
            width="0.5rem"
            css={`
              transform: translateY(-10%);
            `}
          />
        </Flex>
      </Box>
    </Flex>
  </AspectRatio>
)

export const LinkedPhotoCards = ({ children, heading, ...props }) => (
  <BoundedBox as="section" maxWidth="none" {...props}>
    {heading && (
      <Heading
        color="teal"
        textAlign="center"
        fontWeight="normal"
        fontSize={['xlarge', 'xxlarge']}
        mb={[2, 4]}
      >
        {heading}
      </Heading>
    )}
    {children && <Flex flexWrap="wrap">{children}</Flex>}
  </BoundedBox>
)

LinkedPhotoCards.Card = Card
