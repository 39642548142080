import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Carousel from 'nuka-carousel'
import { get, map, compose, debounce } from 'lodash/fp'
import { nodes, isBrowser } from 'helpers'

import { Box, Flex, SVG } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Button } from 'src/components/Button'
import { Heading } from 'src/components/Heading'
import { Physician } from 'src/components/Physician'
import { TapaPatternFloating } from 'src/components/TapaPatternFloating'
import { IconChevronLeft } from 'src/components/IconChevronLeft'
import { IconChevronRight } from 'src/components/IconChevronRight'

const ControlButton = ({ svg, side, ...props }) => (
  <Flex
    alignItems="center"
    border={2}
    borderRadius="50%"
    color="navy"
    cursor="pointer"
    height="3rem"
    justifyContent="center"
    width="3rem"
    mb={3}
    {...props}
  >
    <SVG
      svg={svg}
      x={11}
      y={18}
      fill="navy"
      width="0.5rem"
      css={`
        transform: translateX(${side === 'left' ? '-15%' : '15%'});
      `}
    />
  </Flex>
)

const render = ({ children, slidesToShow, ...props }) => queryData => {
  const physicians = compose(
    nodes,
    get('physicians')
  )(queryData)

  return (
    <BoundedBox
      as="section"
      bg="sandExtraLight"
      color="navy"
      position="relative"
      {...props}
    >
      <TapaPatternFloating />
      <Box position="relative">
        <Heading mb={[4, 6]} textAlign="center">
          Meet our Physicians
        </Heading>
        <Box mb={[4, 6]}>
          <Carousel
            framePadding="0 4rem"
            slidesToShow={slidesToShow}
            heightMode="max"
            renderBottomCenterControls={() => null}
            renderCenterLeftControls={({ previousSlide }) => (
              <ControlButton
                svg={IconChevronLeft}
                onClick={previousSlide}
                side="left"
                aria-label="Previous"
              />
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ControlButton
                svg={IconChevronRight}
                onClick={nextSlide}
                side="right"
                aria-label="Next"
              />
            )}
          >
            {map(
              physician => (
                <Physician
                  key={get('id', physician)}
                  imageFluid={get(
                    'frontmatter.photo.childImageSharp.fluid',
                    physician
                  )}
                  name={get('frontmatter.title', physician)}
                  path={get('frontmatter.path', physician)}
                  mb={0}
                  px={2}
                  {...props}
                />
              ),
              physicians
            )}
          </Carousel>
        </Box>
        <Flex justifyContent="center">
          <Button to="/our-team/">See All Physicians</Button>
        </Flex>
      </Box>
    </BoundedBox>
  )
}

// Returns the number of slides to show based on the current window width.
const calculateSlidesToShow = () => {
  if (!isBrowser) return 1

  if (window.matchMedia('(min-width: 1000px)').matches) {
    return 4
  } else if (window.matchMedia('(min-width: 700px)').matches) {
    return 3
  } else if (window.matchMedia('(min-width: 500px)').matches) {
    return 2
  }

  return 1
}

export const PhysiciansCarousel = props => {
  const [slidesToShow, setSlidesToShow] = useState(calculateSlidesToShow())

  // Set slides to show on resize.
  useEffect(() => {
    if (!isBrowser) return

    const listenerHandler = debounce(250, () => {
      setSlidesToShow(calculateSlidesToShow())

      // Force nuka-carousel to recalculate height.
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 25)
    })

    window.addEventListener('resize', listenerHandler)

    return () => window.removeEventListener('resize', listenerHandler)
  })

  // Fix nuka-carousel sizing bug. Sometimes the carousel will be half-height,
  // cutting off content. This forces nuka-carousel to recalculate the
  // content's height.
  useEffect(() => {
    if (!isBrowser) return

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 25)
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          physicians: allMarkdownRemark(
            sort: { fields: [frontmatter___order] }
            filter: { fileAbsolutePath: { regex: "/(physicians)/.*.md$/" } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  path
                  title
                  photo {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={render({ slidesToShow, ...props })}
    />
  )
}

PhysiciansCarousel.Physician = Physician
