import React from 'react'
import { get } from 'lodash/fp'

import { Box, Flex, Text, GatsbyImage, GatsbyImageContainer } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Subheading } from 'src/components/Subheading'
import { TapaPatternFill } from 'src/components/TapaPatternFill'

const themes = {
  sand: {
    bg: 'sandDark',
    bgOpacity: 0.125,
    color: 'white',
  },
  teal: {
    bg: 'teal',
    bgOpacity: 0.075,
    color: 'white',
  },
}

const Testimonial = ({ theme = 'teal', credit, children, ...props }) => (
  <Flex
    bg={get([theme, 'bg'], themes)}
    color={get([theme, 'color'], themes)}
    flexDirection="column"
    justifyContent="center"
    px={[4, 6]}
    py={[4, 10]}
    position="relative"
    width={[1, 1 / 2]}
    css={`
      align-items: flex-end;
      &:last-child {
        align-items: flex-start;
      }
    `}
    {...props}
  >
    <TapaPatternFill opacity={get([theme, 'bgOpacity'], themes)} />
    <Box maxWidth="calc(80rem / 2 - 3rem)">
      <Text
        fontSize={['large', 'midlarge', 'xlarge']}
        fontWeight="light"
        lineHeight="title"
        mb={[2, 3]}
      >
        {children}
      </Text>
      {credit && (
        <Subheading as="p" color="yellow">
          {credit}
        </Subheading>
      )}
    </Box>
  </Flex>
)

const Photo = ({ imageFluid, ...props }) =>
  imageFluid ? (
    <Box width={[1, 1 / 2]} bg="black" position="relative">
      <GatsbyImageContainer
        height={[null, '100%']}
        left={[null, 0]}
        position={[null, 'absolute']}
        top={[null, 0]}
        width="100%"
      >
        <GatsbyImage
          fluid={imageFluid}
          alt=""
          css={`
            pointer-events: none;
          `}
        />
      </GatsbyImageContainer>
    </Box>
  ) : null

export const Testimonials = ({ children, ...props }) => (
  <BoundedBox pt={0} pb={0} pr={0} pl={0} mx={[3, 4]} my={[3, 4]}>
    <Flex
      flexDirection={['column', 'row']}
      {...props}
      minHeight={[null, '24rem']}
    >
      {children}
    </Flex>
  </BoundedBox>
)

Testimonials.Testimonial = Testimonial
Testimonials.Photo = Photo
