import React from 'react'
import { graphql } from 'gatsby'
import { get, map, compose, propEq, find, set } from 'lodash/fp'
import { nodes } from 'helpers'
import snarkdown from 'snarkdown'

import { SVG } from 'system'
import { Layout } from 'src/components/Layout'
import { LogoBug } from 'src/components/LogoBug'

import { FeaturedLinkBar } from 'src/slices/FeaturedLinkBar'
import { HomepageSlideshow } from 'src/slices/HomepageSlideshow'
import { LinkedPhotoCards } from 'src/slices/LinkedPhotoCards'
import { NotificationBar } from 'src/slices/NotificationBar'
import { PhysiciansCarousel } from 'src/slices/PhysiciansCarousel'
import { SpecialIntroText } from 'src/slices/SpecialIntroText'
import { Testimonials } from 'src/slices/Testimonials'

const IndexPage = ({ data }) => {
  const page = get('home.childMarkdownRemark', data)
  const settings = get('settings.childMarkdownRemark.frontmatter', data)
  const services = compose(
    nodes,
    get('services')
  )(data)

  const hasTestimonials =
    (get('frontmatter.testimonials.testimonial_1.quote', page) &&
      get('frontmatter.testimonials.photo.childImageSharp.fluid', page)) ||
    (get('frontmatter.testimonials.testimonial_2.quote', page) &&
      get('frontmatter.testimonials.testimonial_3.quote', page))

  const featuredServices = compose(
    map(x =>
      set(
        'service',
        find(propEq('frontmatter.path', get('serviceRelation', x)), services)
      )(x)
    ),
    get('frontmatter.featuredServices')
  )(page)

  const noticeContent = get('frontmatter.notice', page)

  return (
    <Layout>
      <HomepageSlideshow>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverStronger.childImageSharp.fluid', data)}
          phrase={() => (
            <>
              We believe in getting you back to <em>being you</em>.
            </>
          )}
        >
          Ever Str
          <SVG
            svg={LogoBug}
            x={1}
            y={1}
            width={['1.5rem', '2.25rem', '3.25rem', '4.35rem']}
            fill="teal"
            ml={['0.15rem', null, '0.2rem', '0.35rem']}
            mr={['0.1rem', null, '0.15rem', '0.2rem']}
            display="inline-block"
          />
          nger
        </HomepageSlideshow.Slide>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverAdvancing.childImageSharp.fluid', data)}
          phrase="We believe in fast, efficient, effective treatment."
          color="white"
        >
          Ever Advancing
        </HomepageSlideshow.Slide>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverSearching.childImageSharp.fluid', data)}
          phrase="We believe in results. More than any other orthopedic practice, our patients get back on their feet faster."
          color="white"
        >
          Ever Searching
        </HomepageSlideshow.Slide>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverAfter.childImageSharp.fluid', data)}
          phrase="We believe in compassion. It's not just the cure, but the conversation, the human connection that matters."
        >
          Ever After
        </HomepageSlideshow.Slide>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverClimbing.childImageSharp.fluid', data)}
          phrase="We believe in the future&mdash;in constantly reaching for it with better technology and expert care."
          color="white"
        >
          Ever Climbing
        </HomepageSlideshow.Slide>
        <HomepageSlideshow.Slide
          imageFluid={get('imageEverStriving.childImageSharp.fluid', data)}
          phrase="We believe in living pain-free lives."
        >
          Ever Striving
        </HomepageSlideshow.Slide>
      </HomepageSlideshow>
      <FeaturedLinkBar
        heading="OA Patient Portal"
        message={get('frontmatter.patient_portal_description', page)}
        buttonText="Log In"
        buttonUrl={get('login_button.url', settings)}
      />
      {noticeContent && <NotificationBar html={snarkdown(noticeContent)} />}
      <SpecialIntroText markdown={get('frontmatter.intro', page)} />
      <LinkedPhotoCards heading="Services" pb={hasTestimonials ? 0 : [6, 10]}>
        {map(
          service => (
            <LinkedPhotoCards.Card
              key={get('service.id', service)}
              to={`/services/${get('service.frontmatter.path', service)}/`}
              heading={get('service.frontmatter.title', service)}
              imageFluid={get('image.childImageSharp.fluid', service)}
            />
          ),
          featuredServices
        )}
        <LinkedPhotoCards.Card to="/services/" heading="See All Services" />
      </LinkedPhotoCards>
      {get('frontmatter.testimonials.testimonial_1.quote', page) &&
        get('frontmatter.testimonials.photo.childImageSharp.fluid', page) && (
          <Testimonials>
            <Testimonials.Testimonial
              credit={get(
                'frontmatter.testimonials.testimonial_1.credit',
                page
              )}
            >
              {get('frontmatter.testimonials.testimonial_1.quote', page)}
            </Testimonials.Testimonial>
            <Testimonials.Photo
              imageFluid={get(
                'frontmatter.testimonials.photo.childImageSharp.fluid',
                page
              )}
            />
          </Testimonials>
        )}
      {get('frontmatter.testimonials.testimonial_2.quote', page) &&
        get('frontmatter.testimonials.testimonial_3.quote', page) && (
          <Testimonials>
            <Testimonials.Testimonial
              credit={get(
                'frontmatter.testimonials.testimonial_2.credit',
                page
              )}
            >
              {get('frontmatter.testimonials.testimonial_2.quote', page)}
            </Testimonials.Testimonial>
            <Testimonials.Testimonial
              theme="sand"
              credit={get(
                'frontmatter.testimonials.testimonial_3.credit',
                page
              )}
            >
              {get('frontmatter.testimonials.testimonial_3.quote', page)}
            </Testimonials.Testimonial>
          </Testimonials>
        )}
      <PhysiciansCarousel />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  fragment HomepageSlideshowImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  query IndexPage {
    settings: file(relativePath: { eq: "settings.md" }) {
      childMarkdownRemark {
        frontmatter {
          login_button {
            url
            name
          }
        }
      }
    }
    imageEverStronger: file(
      relativePath: { eq: "homepage-ever-stronger.jpg" }
    ) {
      ...HomepageSlideshowImage
    }
    imageEverAdvancing: file(
      relativePath: { eq: "homepage-ever-advancing.jpg" }
    ) {
      ...HomepageSlideshowImage
    }
    imageEverSearching: file(
      relativePath: { eq: "homepage-ever-searching.jpg" }
    ) {
      ...HomepageSlideshowImage
    }
    imageEverAfter: file(relativePath: { eq: "homepage-ever-after.jpg" }) {
      ...HomepageSlideshowImage
    }
    imageEverClimbing: file(
      relativePath: { eq: "homepage-ever-climbing.jpg" }
    ) {
      ...HomepageSlideshowImage
    }
    imageEverStriving: file(
      relativePath: { eq: "homepage-ever-striving.jpg" }
    ) {
      ...HomepageSlideshowImage
    }
    home: file(relativePath: { eq: "customPages/home.md" }) {
      childMarkdownRemark {
        frontmatter {
          patient_portal_description
          notice
          intro
          featuredServices: featured_services {
            serviceRelation: service_relation
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          # testimonials {
          #   photo {
          #     childImageSharp {
          #       fluid(maxWidth: 500, quality: 90) {
          #         ...GatsbyImageSharpFluid_withWebp_noBase64
          #       }
          #     }
          #   }
          #   testimonial_1 {
          #     credit
          #     quote
          #   }
          #   testimonial_2 {
          #     credit
          #     quote
          #   }
          #   testimonial_3 {
          #     credit
          #     quote
          #   }
          # }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(services)/.*.md$/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
