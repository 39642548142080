import React from 'react'

import { Flex, Text } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { ButtonBordered } from 'src/components/ButtonBordered'
import { Subheading } from 'src/components/Subheading'
import { TapaPatternFill } from 'src/components/TapaPatternFill'

export const FeaturedLinkBar = ({
  heading,
  message,
  buttonText,
  buttonUrl,
  ...props
}) => (
  <BoundedBox
    as="section"
    bg="teal"
    color="white"
    pt={4}
    pb={4}
    position="relative"
    {...props}
  >
    <TapaPatternFill />
    <Flex
      alignItems="center"
      flexDirection={['column', 'row']}
      position="relative"
    >
      <Flex
        flex="1 1 auto"
        alignItems="center"
        flexDirection={['column', 'row']}
      >
        <Subheading
          as="h3"
          color="yellow"
          mb={[1, 0]}
          mr={[null, 4]}
          textAlign={['center', 'left']}
          whiteSpace="nowrap"
        >
          {heading}
        </Subheading>
        <Text
          flex="1 1 auto"
          fontSize="small"
          lineHeight="copy"
          mb={[3, 0]}
          mr={[null, 4]}
          textAlign={['center', 'left']}
        >
          {message}
        </Text>
      </Flex>
      <ButtonBordered
        to={buttonUrl}
        color="white"
        css={`
          &:hover {
            color: ${p => p.theme.colors.yellow};
            border-color: ${p => p.theme.colors.yellow};
            background-color: transparent;
          }
        `}
      >
        {buttonText}
      </ButtonBordered>
    </Flex>
  </BoundedBox>
)
